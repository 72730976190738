@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
}
/* You can add global styles to this file, and also import other style files */

.buttons {
  button {
    margin-left: 0.5em;
  }
}
